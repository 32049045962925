<template>
  <div class="grid justify-content-around">
    <Card class="mt-2 lg:col-8 col-5" style="height:fit-content; width: fit-content">
      <template #title>
        <span>Welcome to Athene</span>
      </template>
      <template #subtitle>Serial Number Reservation Service</template>
      <template #content>
        <span class="m-2">Quick actions:</span>
        <div class="flex flex-row align-items-center">
          <div class="m-2">
            <LinkButton to="/serial-number-reservation" label="Serial Number Reservation" tag="button"></LinkButton>
          </div>
          <div class="m-2">
            <LinkButton to="/production-orders/pdfs/download" label="Production Orders Download" tag="button">
            </LinkButton>
          </div>
        </div>

      </template>
    </Card>

  </div>
  <div class="flex flex-column m-3 align-items-left">
    <h3>Recently Reserved Serial Numbers</h3>
    <div class="flex flex-row gap-3">
      <Calendar placeholder="Select From Date" inputId="FromDate" v-model="fromDate" :showIcon="true"
        :showButtonBar="true" dateFormat="dd.mm.yy" style="width: 20%" />
      <Calendar placeholder="Select To Date" inputId="FromDate" v-model="toDate" :showIcon="true" :showButtonBar="true"
        dateFormat="dd.mm.yy" style="width: 20%" />
    </div>

    <ConDataTable tableId="recentlyReservedTable" :columns="recentlyReservedTableColumns" filterDisplay="menu"
      :rowsPerPageOptions="[10, 20, 50]" :onReload="fetchRecentlyReservedDataAsync" :exportable="true"
      ref="recentlyReservedTableRef" :createActionMenu="createActionMenu" :exportFilename="fileName">

    </ConDataTable>
  </div>
</template>
<script setup lang="ts">
import { ConDataTable } from "@congatec/primevue-components-lib";
import Card from "primevue/card";
import { SerialNumberReservationsService } from '@/shared/services/athene-api'
import { ref, watch, onMounted } from "vue";
import Calendar from 'primevue/calendar';
import { useFileDownloadStatusStore, FileStatusStoreStatusType } from "@congatec/primevue-components-lib";
import { useToast } from "primevue/usetoast";

// const isAuthorized: Ref<boolean> = ref(false);
//   onKeycloakToken(async (authenticationService: KeycloakService) => {
//   isAuthorized.value = authenticationService.hasRoles(["Admin", "SystemAdmin"]);
// });
const toast = useToast();
const recentlyReservedTableRef = ref<null | typeof ConDataTable>(null);
const fromDate = ref<Date>();
const toDate = ref<Date>();

const now = new Date();
  fromDate.value = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 7
  );
  toDate.value = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  );

const fileName = ref("reserved-serial-numbers");
const recentlyReservedTableColumns = [
  {
    field: "serialNumberId",
    header: "Serial Number Id",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "poolName",
    header: "Pool Name",
    dataType: "text",
    isFiltered: true,
    sortable: true
  },
  {
    field: "startSerialNumber",
    header: "Start Serial Number",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "endSerialNumber",
    header: "End Serial Number",
    dataType: "text",
  },
  {
    field: "description",
    header: "Description",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "amount",
    header: "Amount",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "createdOn",
    header: "Reserved at",
    dataType: "date",
    isFiltered: true,
    sortable: true
  },
  {
    field: "createdBy",
    header: "Reserved by",
    dataType: "text",
    isFiltered: true,
    sortable: true
  },
  {
    field: "exportCounter",
    header: "Export Counter",
    dataType: "text",
    isFiltered: true,
    sortable: true
  },
  {
    field: "reservationGuid",
    header: "Reservation Guid",
    dataType: "text",
    isFiltered: true,
    sortable: true
  },
  {
    field: "actionMenu",
  }
]

watch(() => fromDate.value || toDate.value, (nv) => {
  recentlyReservedTableRef.value?.forceReload();
})

async function fetchRecentlyReservedDataAsync(filters: string, sorts: string, page: number, rows: number): Promise<any> {
  let res = await SerialNumberReservationsService.getApiV1SerialNumberReservationsListRecentlyReserved(
    filters, sorts, page, rows, fromDate.value?.toLocaleDateString('sv'), toDate.value?.toLocaleDateString('sv'))

  fileName.value = "reserved-serial-numbers-" + fromDate.value?.toLocaleDateString('sv') + "-" + toDate.value?.toLocaleDateString('sv');

  return {
    totalCount: res.totalCount,
    data: res.recentlyReservedSerialNumbers,
  }
}

function createActionMenu(row: any): any {
  return [
    {
      label: 'Excel-Export',
      icon: 'pi pi-file-export',
      command: () => {
        downloadFile(row.serialNumberId);
      }
    }
  ];
}

const store = useFileDownloadStatusStore();
let _fileName = '';
const _mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

async function downloadFile(reservationId: any): Promise<void> {
  if (reservationId) {
      emit("xlsx-export-started");
      _fileName = `serial-number-reservation-${reservationId}-export.xlsx`;

      store.add({
        id: reservationId.toString(),
        name: _fileName,
        url: "",
        status: FileStatusStoreStatusType.DOWNLOADING,
      });

      fetchFileContent(reservationId).then(
        (data) => {
          if (data) {
            forceFileDownload(reservationId.toString(), Uint8Array.from(atob(data), (c) => c.charCodeAt(0)), _fileName);
          }
        },
        (error) => {
          emit("xlsx-export-failed");
          store.update({
            id: reservationId.toString(),
            changes: {
              url: "",
              status: FileStatusStoreStatusType.ERROR,
            },
          });
          toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured: ' + error.body.Errors[0].ErrorMessage, life: 5000 });
        }
      );
  }
}

async function fetchFileContent(id: number): Promise<any> {
  try {
    const response = await SerialNumberReservationsService.getApiV1SerialNumberReservationsExportXlsx(id);
    if (response.workbook != null) {
      return response.workbook;
    } else {
      return null;
    }
  }
  catch (ex: any) {
    emit("xlsx-export-failed");
    store.update({
      id: id.toString(),
      changes: {
        url: "",
        status: FileStatusStoreStatusType.ERROR,
      },
    });
    toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured: ' + ex.body.Errors[0].ErrorMessage, life: 5000 });
  }
}

function forceFileDownload(id: string, data: Uint8Array, name: string): void {
  if (data) {
    const url = window.URL.createObjectURL(
      new Blob([data], { type: _mimeType })
    );

    store.update({
      id: id,
      changes: {
        url: url,
        status: FileStatusStoreStatusType.DOWNLOADED,
      },
    });

    // const link = document.createElement("a");
    // link.href = url;
    // link.download = name;
    // link.click();
  }
}

const emit =
  defineEmits<{
    (event: "xlsx-export-started"): void;
    (event: "xlsx-export-failed"): void;
  }>();

// function exportCSV(event: any) {
//   recentlyReservedTableRef.value.exportCSV();
// }
</script>