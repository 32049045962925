/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SettingsCreateRequest } from '../models/SettingsCreateRequest';
import type { SettingsCreateResponse } from '../models/SettingsCreateResponse';
import type { SettingsDeleteRequest } from '../models/SettingsDeleteRequest';
import type { SettingsDeleteResponse } from '../models/SettingsDeleteResponse';
import type { SettingsFindByKeyResponse } from '../models/SettingsFindByKeyResponse';
import type { SettingsListOrderFields } from '../models/SettingsListOrderFields';
import type { SettingsListOrderFieldsOrderingFieldsBase } from '../models/SettingsListOrderFieldsOrderingFieldsBase';
import type { SettingsListResponse } from '../models/SettingsListResponse';
import type { SettingsUpdateRequest } from '../models/SettingsUpdateRequest';
import type { SettingsUpdateResponse } from '../models/SettingsUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SettingsService {

    /**
     * @param requestBody 
     * @returns SettingsCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1SettingsCreate(
requestBody?: SettingsCreateRequest,
): CancelablePromise<SettingsCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Settings/Create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns SettingsUpdateResponse Success
     * @throws ApiError
     */
    public static putApiV1SettingsEdit(
requestBody?: SettingsUpdateRequest,
): CancelablePromise<SettingsUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Settings/Edit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns SettingsDeleteResponse Success
     * @throws ApiError
     */
    public static deleteApiV1SettingsDelete(
requestBody?: SettingsDeleteRequest,
): CancelablePromise<SettingsDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Settings/Delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pageNumber 
     * @param pageSize 
     * @param skip 
     * @param orderingFields 
     * @param availableOrderingFields 
     * @returns SettingsListResponse Success
     * @throws ApiError
     */
    public static getApiV1SettingsList(
pageNumber?: number,
pageSize?: number,
skip?: number,
orderingFields?: Array<SettingsListOrderFieldsOrderingFieldsBase>,
availableOrderingFields?: SettingsListOrderFields,
): CancelablePromise<SettingsListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Settings/List',
            query: {
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'Skip': skip,
                'OrderingFields': orderingFields,
                'AvailableOrderingFields': availableOrderingFields,
            },
        });
    }

    /**
     * @param key 
     * @returns SettingsFindByKeyResponse Success
     * @throws ApiError
     */
    public static getApiV1Settings(
key: string,
): CancelablePromise<SettingsFindByKeyResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Settings/{key}',
            path: {
                'key': key,
            },
        });
    }

}
