/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SerialNumberPoolsCreateRequest } from '../models/SerialNumberPoolsCreateRequest';
import type { SerialNumberPoolsCreateResponse } from '../models/SerialNumberPoolsCreateResponse';
import type { SerialNumberPoolsDeleteResponse } from '../models/SerialNumberPoolsDeleteResponse';
import type { SerialNumberPoolsDetailsResponse } from '../models/SerialNumberPoolsDetailsResponse';
import type { SerialNumberPoolsFindResponse } from '../models/SerialNumberPoolsFindResponse';
import type { SerialNumberPoolsListOrderFields } from '../models/SerialNumberPoolsListOrderFields';
import type { SerialNumberPoolsListOrderFieldsOrderingFieldsBase } from '../models/SerialNumberPoolsListOrderFieldsOrderingFieldsBase';
import type { SerialNumberPoolsListResponse } from '../models/SerialNumberPoolsListResponse';
import type { SerialNumberPoolsUpdateRequest } from '../models/SerialNumberPoolsUpdateRequest';
import type { SerialNumberPoolsUpdateResponse } from '../models/SerialNumberPoolsUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SerialNumberPoolsService {

    /**
     * @param requestBody 
     * @returns SerialNumberPoolsCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1SerialNumberPools(
requestBody?: SerialNumberPoolsCreateRequest,
): CancelablePromise<SerialNumberPoolsCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/SerialNumberPools',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param deleted 
     * @param includeDeleted 
     * @param pageNumber 
     * @param pageSize 
     * @param skip 
     * @param orderingFields 
     * @param availableOrderingFields 
     * @returns SerialNumberPoolsListResponse Success
     * @throws ApiError
     */
    public static getApiV1SerialNumberPools(
deleted?: boolean,
includeDeleted?: boolean,
pageNumber?: number,
pageSize?: number,
skip?: number,
orderingFields?: Array<SerialNumberPoolsListOrderFieldsOrderingFieldsBase>,
availableOrderingFields?: SerialNumberPoolsListOrderFields,
): CancelablePromise<SerialNumberPoolsListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/SerialNumberPools',
            query: {
                'Deleted': deleted,
                'IncludeDeleted': includeDeleted,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'Skip': skip,
                'OrderingFields': orderingFields,
                'AvailableOrderingFields': availableOrderingFields,
            },
        });
    }

    /**
     * @param poolId 
     * @returns SerialNumberPoolsDetailsResponse Success
     * @throws ApiError
     */
    public static getApiV1SerialNumberPools1(
poolId: number,
): CancelablePromise<SerialNumberPoolsDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/SerialNumberPools/{poolId}',
            path: {
                'poolId': poolId,
            },
        });
    }

    /**
     * @param poolId 
     * @param requestBody 
     * @returns SerialNumberPoolsUpdateResponse Success
     * @throws ApiError
     */
    public static putApiV1SerialNumberPools(
poolId: number,
requestBody?: SerialNumberPoolsUpdateRequest,
): CancelablePromise<SerialNumberPoolsUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/SerialNumberPools/{poolId}',
            path: {
                'poolId': poolId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param poolId 
     * @returns SerialNumberPoolsDeleteResponse Success
     * @throws ApiError
     */
    public static deleteApiV1SerialNumberPools(
poolId: number,
): CancelablePromise<SerialNumberPoolsDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/SerialNumberPools/{poolId}',
            path: {
                'poolId': poolId,
            },
        });
    }

    /**
     * @param name 
     * @returns SerialNumberPoolsFindResponse Success
     * @throws ApiError
     */
    public static getApiV1SerialNumberPools2(
name: string,
): CancelablePromise<SerialNumberPoolsFindResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/SerialNumberPools/{name}',
            path: {
                'name': name,
            },
        });
    }

}
