<template class="align-self-start">
    <div>
      <ConCard style="margin-bottom: 10px">
        <template #title>Production Orders Download </template>
          <i style="font-size: 200%" class="pi-download"></i>
        <template #content>
          <InputText type="text" v-model="model" style="width:50%" placeholder="Enter valid Production Order Number" />
        </template>
        <template #footer>
          <Button icon="pi-file-pdf" label="Cancel" class="p-button-secondary" @click="checkout" :disabled="!(model !== undefined && model !== undefined && model.trim() !== '')" style="font-weight: bold; margin-left: .5em">Download</Button>
        </template>
      </ConCard>
    </div>
  </template>
  
  <script setup lang="ts">
  import { type Ref, defineComponent } from "vue";
  import { ref } from 'vue';
  // import { useStore } from 'vuex'
import { ProductionOrdersService } from "@/shared/services/athene-api";
import Button from "primevue/button";
import InputText from 'primevue/inputtext';
import { ConCard } from '@congatec/primevue-components-lib';
import { useFileDownloadStatusStore, FileStatusStoreStatusType } from "@congatec/primevue-components-lib";

  // const store = useStore();
  const model: Ref<string|undefined> = ref();
  const store = useFileDownloadStatusStore();

function checkout() {
  if (model.value) {
    var id = `${model.value ?? "dummy"}`;

    ProductionOrdersService.getApiV1ProductionOrdersRevisionsPdfsCheckout(
      model.value ?? ""
    )
    .then(
        (response) => {

          if (response.pdfs) {
            response.pdfs.forEach((element) => {
                if (element.path != null && element.content != null) {
                  const pdfId = `REV_${element.svnRevision}_PATH_${element.path}`;
                  
                  store.add({
                    id: pdfId,
                    name: element.path,
                    url: "",
                    status: FileStatusStoreStatusType.DOWNLOADING,
                  });

                  setFileFinished(
                      pdfId,
                      element.content,
                      element.path,
                      "application/pdf"
                  );

                  const byteCharacters = atob(element.content);
                  const byteNumbers = new Array(byteCharacters.length);
                  for (let i = 0; i < byteCharacters.length; i++) {
                      byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);
                }
            });
          }
        },
      );
  }
}

function setFileFinished(id: string, data: any, name: any, type: string) {
    if (data) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const url = window.URL.createObjectURL(
        new Blob([byteArray], { type: type })
    );

    store.update({
      id: id,
        changes: {
          url: url,
          status: FileStatusStoreStatusType.DOWNLOADED,
        },
    });


    // const link = document.createElement("a");
    // link.hidden = true;
    // link.href = url;
    // link.setAttribute("download", name);
    // document.body.appendChild(link);
    // link.click();
    }
}
  </script>

<script lang="ts">
export default defineComponent({
  name: "ProductionOrdersCheckoutView",
});


</script>
  