<template>
    <div class="flex flex-column gap-3">
        <div class="w-full">
            <label for="name">Name <span>*</span></label>
            <InputText id="name" v-model="state.Name" :value="state.Name" placeholder="Enter the name for the new cluster"
                class="w-full" :class="{ 'input-invalid': v$.Name.$invalid && v$.Name.$error, 'h-3rem': true }" />
            <span v-if="v$.Name.$error">{{
                v$.Name.$errors[0].$message }}
            </span>
        </div>
        <div class="w-full">
            <label for="description">Description</label>
            <InputText id="description" v-model="state.Description" placeholder="Enter the description for the new cluster"
                class="w-full" />
        </div>
        <div class="card">
            <label>Serial Number Pools</label>
            <PickList v-model="pickListArray" dataKey="id" breakpoint="1400px">
                <template #sourceheader> Available </template>
                <template #targetheader> Selected </template>
                <template #item="slotProps">
                    <span>{{ slotProps.item.name }}</span>
                </template>
            </PickList>
        </div>
        <div class="card">
            <label>Departments</label>
            <PickList v-model="departmentPickListArray" dataKey="id" breakpoint="1400px">
                <template #sourceheader> Available </template>
                <template #targetheader> Selected </template>
                <template #item="slotProps">
                    <span>{{ slotProps.item.name }}</span>
                </template>
            </PickList>
        </div>
        <div class="flex flex-row w-full justify-content-end">
            <Button icon="pi pi-save" label="Save" @click="onSave" ></Button>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref, watch, onMounted, type Ref, inject } from 'vue';
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import PickList from 'primevue/picklist';
import { AuthenticationObserver } from '@/base/AuthenticationObserver';
import { useKeycloak } from "@congatec/authentication-lib";
import { useToast } from "primevue/usetoast";
import { type PoolClusterDetailsResponse_Pool, PoolClusterService, type SerialNumberPoolsListResponse_SerialNumberPool, SerialNumberPoolsService, DepartmentService } from '../../shared/services/athene-api';

const toast = useToast();
let authenticationService = useKeycloak();
let authObserver = new AuthenticationObserver(authenticationService);
const serialNumberPools: Ref<Array<any>> = ref([]);
const departments: Ref<Array<any>> = ref([]);
const existingSerialNumberPools: Ref<Array<any>> = ref([]);
const existingDepartments: Ref<Array<any>> = ref([]);
const pickListArray: Ref<Array<Array<any>>> = ref([[]]);
const departmentPickListArray: Ref<Array<Array<any>>> = ref([[]]);
    
const props = defineProps<{
    clusterId?: number
}>();

const dialogRef: any = inject('dialogRef');
let paramClusterId = props.clusterId || dialogRef.value.data?.clusterId;
    
const state = ref({
    Name: '',
    Description: '',
});

const rules = {
    Name: { required },
    //description: { required },
}
const v$ = useVuelidate(rules, state);

// onMounted(() => {

// });

authObserver.onLoggedIn(async () => {
    // now we are logged in!
    console.log("onLoggedIn");
    if (paramClusterId) {
        await fetchExistingData(paramClusterId);
    }
    const poolsResponse = await SerialNumberPoolsService.getApiV1SerialNumberPools(false, false, 1, 1000);
    existingSerialNumberPools.value = poolsResponse.serialNumberPools?.map(x => ({ id: x.id, name: x.name })) || [];
    existingSerialNumberPools.value = existingSerialNumberPools.value.filter(x => !serialNumberPools.value.some(y => y.id === x.id));

    pickListArray.value = [existingSerialNumberPools.value, serialNumberPools.value];

    // department
    const departmentsResponse = await DepartmentService.getApiV1Department('', '', 1, 1000);
    existingDepartments.value = departmentsResponse.departments || [];
    existingDepartments.value = existingDepartments.value.filter(x => !departments.value.some(y => y.id === x.id));

    departmentPickListArray.value = [existingDepartments.value, departments.value]

});

const fetchExistingData = async (id: number) => {
    const item = await PoolClusterService.getApiV1PoolCluster1(id);
    state.value.Name = item.name ?? "";
    state.value.Description = item.description ?? "";
    serialNumberPools.value = item.pools?.map(x => ({ id: x.id, name: x.name })) || [];
    departments.value = item.departments || [];   

}

const onSave = async () => {
    console.log("pickListArray.value: ", pickListArray.value[1]);

    await v$.value.$reset();
    await v$.value.$validate(); // checks all inputs
    if (v$.value.$error) {
        return;
    }

    if(paramClusterId) {
        try {
            await PoolClusterService.putApiV1PoolCluster(paramClusterId, { clusterId: paramClusterId, name: state.value.Name, description: state.value.Description, poolIds: pickListArray.value[1].map(x => x.id), departmentIds: departmentPickListArray.value[1].map(x => x.id) });
            toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Cluster was updated', life: 3000 });
            dialogRef.value.close();
        } catch(ex: any) {
            console.log("Error: Pool Cluster could not be updated: ", ex.body.Errors[0].ErrorMessage);
            toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured while updating the cluster: ' + ex.body.Errors[0].ErrorMessage, life: 3000 });            
        }
    }
    else {
        try {
            await PoolClusterService.postApiV1PoolCluster({ name: state.value.Name, description: state.value.Description, poolIds: pickListArray.value[1].map(x => x.id), departmentIds: departmentPickListArray.value[1].map(x => x.id) });
            toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Cluster was created', life: 3000 });
            dialogRef.value.close();
        } catch(ex: any) {
                console.log("Error: Pool Cluster could not be created: ", ex.body.Errors[0].ErrorMessage);
                toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured while creating the cluster: ' + ex.body.Errors[0].ErrorMessage, life: 3000 });
        }
    }
}


</script>
<style>
.p-picklist-list {
    height: 200px;
}

</style>