<template>
  <div>
    <div
      v-if="
        serialNumberPoolDetails != null &&
        refPool.id !== 0 &&
        serialNumberPoolDetails.id
      "
    >
      <ConDetails title="" :subTitle="props.propPool.name" :contentItems="contentItems">
        <template #item="{ content }">
          <div v-for="c in content" :key="c" style="margin-bottom: 16px;">
            <div  class="flex justify-content-between">
              <div>
                <span >{{c.title}}:</span>
              </div>
              <div>
                {{c.content}}
              </div>
            </div>
          <!-- <Divider /> -->
          </div>
          <div class="flex gap-2 align-items-center">
            <span>Available:</span>
            <div class="flex-wrap w-full">
              <ProgressBar :value="computePoolProgress(props.propPool)" :class="getProgressBarClass(props.propPool)" >
                <!-- :class="{ 'p-progressbar-value-yellow': computePoolProgress(propMatching.data)>90 }" -->
                <!-- Progressbar label syle is set to "display: none" by theme -->
              </ProgressBar>
              <span>{{ props.propPool.rangeTo - props.propPool.currentSerialNumber }}/{{ props.propPool.rangeTo - props.propPool.rangeFrom }} ({{ Math.round(computePoolProgress(props.propPool)) }}%)</span>
            </div>
          </div>
      </template>
      <template #footer>
      </template>
      </ConDetails>
    </div>
    <div v-else>
      <ConDetails title="" subTitle="Pool Details" :contentItems="[{title: '', content: 'Please select a Serial Number Pool'}]">
        <template #item="{ content }">
          <div>
            <p style="font-style:italic">{{ content }}</p>
          </div>
        </template>
        <template #footer>
      </template>
      </ConDetails>
    </div>
  </div>
</template>
<script setup lang="ts">
import { type Ref, defineComponent, onMounted, ref, toRef, watch } from "vue";
import { ConDetails } from '@congatec/primevue-components-lib';
import { type SerialNumberPoolsDetailsResponse, SerialNumberPoolsService } from "@/shared/services/athene-api";
import Divider from "primevue/divider";
import ProgressBar from 'primevue/progressbar';

  const serialNumberPoolDetails: Ref<SerialNumberPoolsDetailsResponse | null> = ref(null);
  const isLoading = ref(true);
  //let contentItems = ref([{title:'', content: ''}]);
  let contentItems: Ref<any> = ref();
  

  onMounted(() => {

    if (props.propPool) {
      _fetchDataFromService();
    }
  });

  const props = defineProps({
    // propPoolId?: Number,
    // propShowHeader?: Boolean,
    // propShowSubHeader?: Boolean
    propPool:{
      type: Object,
      default: null
    },
    propShowHeader:{
      type: Boolean,
      default: false,
    },
    propShowSubHeader:{
      type: Boolean,
      default: true,
    },
  });
  console.log("propPoolId: ", props?.propPool);

  const refPool = toRef(props, 'propPool');

  watch(refPool, _fetchDataFromService);

  async function _fetchDataFromService() {
    if (Number (refPool.value.id) >= 0) {
      isLoading.value = true;
      SerialNumberPoolsService.getApiV1SerialNumberPools1(Number(refPool.value.id))
        .then((response) => {
          isLoading.value = false;
          if (response) {
            var poolDetails = response;
            console.log("NOTICED CHANGE", poolDetails);
            if (poolDetails) {
              serialNumberPoolDetails.value = poolDetails;
              refreshContentItems();
              // emit(
              //   SerialNumberPoolsDetailsViewEvents.SerialNumberPoolsDetailsSuccess,
              //   serialNumberPoolDetails
              // );
            }
          }
        })
        .catch((error) => {
          isLoading.value = false;
        });
    }
  }

  function refreshContentItems() {
    contentItems.value = [{
    title: 'Details', content: [
      {
          title: "Id",
          content: serialNumberPoolDetails.value?.id?.toString(),
      },
      {
          title: "Name",
          content: serialNumberPoolDetails.value?.name,
      },
      {
          title: "Description",
          content: serialNumberPoolDetails.value?.description,
      },
      {
          title: "Current Serial Number",
          content: serialNumberPoolDetails.value?.currentSerialNumber?.toString(),
      },
      {
          title: "Range from",
          content: serialNumberPoolDetails.value?.rangeFrom?.toString(),
      },
      {
          title: "Range to",
          content: serialNumberPoolDetails.value?.rangeTo?.toString(),
      },
      {
          title: "Maximum Requests per Day",
          content: serialNumberPoolDetails.value?.maxRequestsPerDay?.toString(),
      },
      {
          title: "Maximum Serial Numbers by Request",
          content: serialNumberPoolDetails.value?.maxSerialNumbersByRequest?.toString(),
      }
    ]
  }];
  }

  function computePoolProgress(data: any ) {
    if(data.rangeTo !== undefined && data.rangeFrom !== undefined && data.currentSerialNumber !== undefined) {
      return(100/(data.rangeTo - data.rangeFrom))*(data.rangeTo - data.currentSerialNumber);
    }
    return 0;
  }

  function getProgressBarClass(data: any) {
    let progress = computePoolProgress(data);
    if(progress > 30)
      return 'greenbar';
    else if(progress <= 30 && progress > 10)
      return 'yellowbar';
    else return 'redbar';
  }


// const emit =
//   defineEmits<{
//     (event: SerialNumberPoolsDetailsViewEvents.SerialNumberPoolsDetailsSuccess, serialNumberPoolDetails: any): void;
//   }>();
</script>

<script lang="ts">
  export default defineComponent({
    name: "SerialNumberPoolsDetailsView",
  });

  export enum SerialNumberPoolsDetailsViewEvents {
    SerialNumberPoolsDetailsSuccess = "SerialNumberPoolsDetailsSuccess",
  }
</script>
<style lang="css"> 

.details-label {
  text-decoration: underline;

}

.greenbar.p-progressbar .p-progressbar-value {
  background: green !important;
}

.yellowbar.p-progressbar .p-progressbar-value {
  background: yellow !important;
}

.redbar.p-progressbar .p-progressbar-value {
  background: red !important;
}

</style>