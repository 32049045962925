<template>
  <CardsComponent>
    <template #subtitle>
      Reserved Serial Numbers
    </template>
    <template #content>
      <span  v-if="serialNumbers != null && serialNumbers?.length > 3" >
        <ul :id="'summaryList' + props.propReservationId" style="list-style-type: none;">
          <li v-for="nmbr in serialNumbers?.slice(0, 3)"  v-bind:key="nmbr.value" ><span style="margin-left: 8px;">{{ nmbr.value }}</span></li>
        </ul>
        <details :id="'detailsSerialNumbers' + props.propReservationId" @toggle="toggleDetails">
          <summary>{{ summary }}</summary>
          <ul style="list-style-type: none;">
            <li v-for="nmbr in serialNumbers" v-bind:key="nmbr.value"><span style="margin-left: 8px;">{{ nmbr.value }}</span></li>
          </ul>
        </details>
      </span>
      <span  v-else>
        <ul style="list-style-type: none;">
          <li v-for="nmbr in serialNumbers" v-bind:key="nmbr.value"><span style="margin-left: 8px;">{{ nmbr.value }}</span></li>
        </ul>
      </span>
    </template>
    <template #footer>
      <div class="flex flex-wrap flex-row">
        <Button style="margin-right: 8px;" text icon="pi pi-clone" label="Copy to clipboard" @click="copySerialNumbersToClipBoard" />
        <!-- <SerialNumberReservationExportXlsxView
            :propReservationId="propReservationId"
          >
        </SerialNumberReservationExportXlsxView> -->
      </div>
    </template>
  </CardsComponent>
</template>

<script setup lang="ts">
import { SerialNumberReservationsService } from '@/shared/services/athene-api';
import { SerialNumberReservationExportXlsxView } from '@/components/serial-number-reservations';
import { type Ref, defineComponent, onMounted, ref, toRef, watch } from 'vue';
import Button from "primevue/button";

const serialNumbers: Ref<Array<{ value: number }> | null> = ref(null);
const isLoading = ref(true);

const props = defineProps({
  propReservationId: {
    default: (): any => 0
  },
  propShowSubHeader: {
    type: Boolean,
    default: true,
  },
});

const propReservationIdRef = toRef(props, 'propReservationId');
let summary = ref("...");

onMounted(() => {
  console.log("SerialNumberReservationSerialNumbersList onMounted: propReservationIdRef", propReservationIdRef.value);
  if (serialNumbers?.value == null) {
    _fetchDataFromService();
  }
});

watch(propReservationIdRef, _fetchDataFromService);

async function _fetchDataFromService() {
  console.log("SerialNumberReservationSerialNumbersList _fetchDataFromService: propReservationId", propReservationIdRef.value);
  SerialNumberReservationsService.getApiV1SerialNumberReservations(
    Number(propReservationIdRef.value)
  )
    .then((response) => {
      if (response && response.serialNumbers) {
        serialNumbers.value = response.serialNumbers.map((s: number) =>
            Object.assign({}, { value: s }));
      }
      isLoading.value = false;


    })
    .catch((error) => {
      isLoading.value = false;
    });
}

function copySerialNumbersToClipBoard() {
    if(serialNumbers.value !== null && serialNumbers.value)
    {
      var textToCopy = serialNumbers.value.map((s) => s.value)?.join(",");
      console.log("COPIED: ", textToCopy);

      const tmpTextField = document.createElement("textarea");
      tmpTextField.textContent = textToCopy ?? "";
      tmpTextField.setAttribute("style", "position:absolute; right:200%;");
      document.body.appendChild(tmpTextField);
      tmpTextField.select();
      tmpTextField.setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand("copy");
      tmpTextField.remove();

    }
  }

  function toggleDetails() {
    const detailsElement = document.getElementById("detailsSerialNumbers" + props.propReservationId) as HTMLDetailsElement;
    const summaryList = document.getElementById("summaryList" + props.propReservationId);
    if(summaryList != null) {
      if (detailsElement != null && detailsElement.open) {
        summaryList.style.display = "none";
        summary.value = "";
      } else {
        summaryList.style.display = "";
        summary.value = "..."
      }
    }
  }

</script>

<script lang="ts">
export default defineComponent({
  name: "SerialNumberReservationSerialNumbersListView",
});

</script>

<style lang="scss" scoped>

summary:hover {
	text-decoration: underline;
}
</style>