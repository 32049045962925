<template>
    <Dialog v-if="dialogVisible" v-model:visible="dialogVisible" modal :draggable="false" :header="headerText"
      position="top" style="max-width: 80%; min-width: 80%; max-height: 80%;">
      <template #default>
        <div class="card flex justify-content-left">
          <div class="flex flex-column  gap-5 w-full">
            <div class="flex flex-row gap-5 w-full">
              <div class="w-full">
                <label for="key">Key</label>
                <InputText id="key" v-model="state.Key" :value="state.Key"
                  placeholder="Enter the Setting Key" class="w-full" />
              </div>
              <div class="w-full">
                <label for="value">Value</label>
                <InputText id="lastname" v-model="state.Value" placeholder="Enter the Setting Value"
                  class="w-full" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex flex-row align-items-end justify-content-between">
          <Button class="p-button-text" label="Cancel" :onClick="() => {
            dialogVisible = false;
          }" />
  
          <!-- <ConMessages class=""
            v-if="v$.Firstname.$invalid || v$.Lastname.$invalid || v$.Email.$invalid || v$.Country.$invalid"
            severity="error">
            <template #messageContent>
                {{ v$.$errors[0].$property + " " + v$.$errors[0].$message }}
            </template>
          </ConMessages> -->
  
          <Button class="p-button-primary" label="Save" :onClick="async () => {
            if(props.setting) {
              await dispatchEditConfigurationAsync({
                id: $props.setting.id,
                key: state.Key,
                value: state.Value
              })
              
              return;
            }
  
            await dispatchCreateConfigurationAsync({
              key: state.Key,
              value: state.Value
            } as Partial<SettingsCreateRequest>)
          }" />
        </div>
      </template>
    </Dialog>
  </template>
  <script setup lang="ts">
  import { ref, watch } from 'vue';
  import Dialog from 'primevue/dialog'
  import InputText from 'primevue/inputtext';
  import Button from 'primevue/button';
  // import { delay, useDelayTimer, ConMessages } from '@congatec/primevue-components-lib';
  import { useVuelidate } from '@vuelidate/core'
  import { required } from '@vuelidate/validators'
  import { SettingsService, type SettingsCreateRequest, type SettingsUpdateRequest } from '@/shared/services/athene-api'
  
  const props = defineProps<{
    setting?: any,
    visible: boolean,
    onHide: (edited: boolean) => any
  }>();
  
  const state = ref({    
    Key: '',
    Value: ''
  });
  
  const dialogVisible = ref(false);

  let headerText = "Create a new Setting";
  
  
  const rules = {
      Key: { required },
      //description: { required },
  }
  const v$ = useVuelidate(rules, state, {$lazy: true});
  
  
  watch(() => props.visible, (nv) => {
    console.log("VISIBLE: ", nv);
    console.log("Prop visitor", props.setting)
    if (nv) {
      dialogVisible.value = nv;
    }
    if(props.setting) {
      headerText = "Edit Setting"
      state.value.Key = props.setting.key;
      state.value.Value = props.setting.value;
    }
  }, { deep: true, immediate: true });
  
  watch(() => dialogVisible.value, (nv) => {
    if (nv != null && !nv) {
      v$.value.$reset();
      props.onHide(true);
    }
  })
  
  
  async function dispatchEditConfigurationAsync(editSettingsRequest: SettingsUpdateRequest) {
    await v$.value.$reset();
    await v$.value.$validate(); // checks all inputs
    if(v$.value.$error) {
      return;
    }
    await SettingsService.putApiV1SettingsEdit(editSettingsRequest);
    dialogVisible.value = false;
  }
  
  async function dispatchCreateConfigurationAsync(createSettingsRequest: SettingsCreateRequest) {
    console.log("Setting Create: ", createSettingsRequest)
    await v$.value.$reset();
    await v$.value.$validate() // checks all inputs
    if(v$.value.$error) {
      return;
    }
    await SettingsService.postApiV1SettingsCreate(createSettingsRequest)
    dialogVisible.value = false;
  }
  
  </script>
  