<template>
    <DynamicDialog />
    <ConfirmDialog />
    <Toolbar>
        <template #start>
            <div class="flex flex-row gap-3">
                <Button icon="pi pi-folder-plus" label="New Cluster" class="p-button-sm " @click="onNewClusterClick" />
                <Button icon="pi pi-file-plus" label="New Pool" class="p-button-sm " @click="onNewPoolClick" />
            </div>

        </template>
    </Toolbar>
    <div class="flex flex-column  gap-1 m-5 mt-5">
        <div class="flex flex-row align-items-start gap-2">
            <Button type="button" text icon="pi pi-plus" label="Expand All" @click="expandAll" />
            <Button type="button" text icon="pi pi-minus" label="Collapse All" @click="collapseAll" />
            <div class="flex flex-column align-self-end">
                <!-- <Button :disabled="Object.keys(selectedKey).length==0" type="button" icon="pi pi-file-download" label="Download" @click="onDownload" /> -->
            </div>
        </div>
        <Tree v-model:expandedKeys="expandedKeys" :value="nodes" v-model:selectionKeys="selectedKey" selectionMode="single"
            class="w-full md:w-[30rem]">
            <template #default="slotProps">
                <div class="flex flex-nowrap align-items-center">
                    <p>{{ slotProps.node.label }}</p>
                    <Button icon="pi pi-pencil" rounded text aria-label="Edit"
                        @click.stop="onEditClusterClick(slotProps.node.key!, slotProps.node.label!)"></Button>
                    <Button icon="pi pi-trash" rounded text aria-label="Delete" class="p-button-danger"
                        @click.stop="onDeleteClusterClick(slotProps.node.key!, slotProps.node.label!)"></Button>
                </div>
            </template>
            <template #pool="slotProps">
                <div class="flex flex-nowrap align-items-center">
                    <p>{{ slotProps.node.label }}</p>
                    <Button icon="pi pi-pencil" rounded text aria-label="Edit"
                        @click.stop="onEditPoolClick(slotProps.node.key, slotProps.node.label)"></Button>
                    <Button icon="pi pi-trash" rounded text aria-label="Delete" class="p-button-danger"
                        @click.stop="onDeletePoolClick(slotProps.node.key, slotProps.node.label)"></Button>
                </div>
            </template>
        </Tree>
    </div>
</template>
<script setup lang="ts">
import ConfirmDialog from 'primevue/confirmdialog';
import DynamicDialog from 'primevue/dynamicdialog';
import Tree from 'primevue/tree';
import { type Ref, ref, onMounted } from 'vue';
import type { TreeNode } from 'primevue/tree';
import Button from 'primevue/button';
import { PoolClusterService, type SerialNumberPoolsListResponse_SerialNumberPool, type PoolClusterListResponse_PoolCluster, SerialNumberPoolsService } from '@/shared/services/athene-api';
import { useDialog } from 'primevue/usedialog';
import { useConfirm } from 'primevue/useconfirm';
import { SerialNumberPoolInput } from '@/components/serial-number-pools/input';
import { PoolClusterInput } from '@/components/pool-cluster'
import Toolbar from 'primevue/toolbar';
import { useToast } from "primevue/usetoast";

const nodes: Ref<Array<TreeNode>> = ref([]);
const expandedKeys: Ref<any> = ref({});
const selectedKey: Ref<any> = ref({});
const selectedNodes: Ref<Array<string>> = ref([]);
const dialog = useDialog();
const confirm = useConfirm();
const toast = useToast();

onMounted(async () => {
    await onInit();
});

const onInit = async () => {
    let response = (await PoolClusterService.getApiV1PoolCluster(undefined, undefined, 1, 1000));
    let result: any[] = [];
    let level = { result };
    response.poolCluster?.forEach((cluster: PoolClusterListResponse_PoolCluster) => {
        let pools: Array<TreeNode> = [];
        cluster.serialNumberPools?.forEach((pool: SerialNumberPoolsListResponse_SerialNumberPool) => {
            let poolNode: TreeNode = { key: pool.id?.toString(), label: pool.name!, icon: 'pi pi-file', children: [], type: 'pool' };
            pools.push(poolNode);
        });
        let clusterNode: TreeNode = { key: cluster.id?.toString(), label: cluster.name!, icon: 'pi pi-folder', children: pools };
        nodes.value.push(clusterNode);
    });
}

const addNodes = (entry: any) => {
    if (entry.children.length > 0) {
        let node: TreeNode = { key: entry.Id.toString(), label: entry.name, icon: 'pi pi-folder', children: [] };
        entry.children.forEach((child: any) => {
            let childNode = addChildNode(entry, child);
            if (childNode != null) {
                node.children?.push(childNode);
            }
            else {
                node.selectable = false;
            }
        },
            nodes.value.push(node));
    }
    else {
        let node: TreeNode = { key: entry.name, label: entry.name, icon: 'pi pi-file', type: 'file' };
        nodes.value.push(node);
    }
};

const addChildNode = (parent: any, child: any) => {
    if (child.children && child.children.length > 0) {
        let node: TreeNode = { key: (parent.name || parent.key) + "/" + child.name, label: child.name, icon: 'pi pi-folder', children: [] };
        child.children.forEach((child: TreeNode) => {
            let add = addChildNode(node, child);
            if (add != null) {
                node.children?.push(add)
            }
        });
        return node;
    }
    else if (child.name != "") {
        let node: TreeNode = { key: (parent.name || parent.key) + "/" + child.name, label: child.name, icon: 'pi pi-file', type: 'file' };
        return node;
    }
    return null;
}

const expandAll = () => {
    for (let node of nodes.value) {
        expandNode(node);
    }

    expandedKeys.value = { ...expandedKeys.value };
};

const collapseAll = () => {
    expandedKeys.value = {};
};

const expandNode = (node: TreeNode) => {
    if (node.key && node.children && node.children.length) {
        expandedKeys.value[node.key] = true;

        for (let child of node.children) {
            expandNode(child);
        }
    }
};

const onNewClusterClick = () => {
    //newDisabled.value = true;
    dialog.open(PoolClusterInput, {
        data: {
        },
        onClose: onNewClusterClosed,
        props: {
            header: "New Pool Cluster",
            style: { width: '50vw' }
        },
    }
    );
}

const onNewClusterClosed = async (_options: any) => {
    nodes.value = [];
    await onInit();
}

const onNewPoolClick = () => {
    //newDisabled.value = true;
    dialog.open(SerialNumberPoolInput, {
        data: {
        },
        onClose: onNewPoolClosed,
        props: {
            header: "New Pool",
            style: { width: '50vw' }
        },
    }
    );
}

const onNewPoolClosed = async (_options: any) => {
    nodes.value = [];
    await onInit();
}

const onEditPoolClick = (key: string, name: string) => {
    console.log("onEdit: ", name);
    //deleteDisabled.value = true;
    dialog.open(SerialNumberPoolInput, {
        data: {
            poolId: key
        },
        onClose: onEditPoolClosed,
        props: {
            header: "Edit Serial Number Pool " + name,
            style: { width: '50vw' }
        },
    }
    );
}

const onEditPoolClosed = async (_options: any) => {
    nodes.value = [];
    await onInit();
}

const onEditClusterClick = (key: string, name: string) => {
    console.log("onEdit: ", key);
    //deleteDisabled.value = true;
    dialog.open(PoolClusterInput, {
        data: {
            clusterId: key
        },
        onClose: onEditClusterClosed,
        props: {
            header: "Edit Pool Cluster " + name,
            style: { width: '50vw' }
        },
    }
    );
}

const onEditClusterClosed = async (_options: any) => {
    nodes.value = [];
    await onInit();
}

const onDeleteClusterClick = (key: string, name: string) => {
    confirm.require({
        message: 'Do you want to delete the cluster [' + name + ']?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
            if (key) {
                try {
                    const response = await PoolClusterService.deleteApiV1PoolCluster(Number(key));
                    toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Cluster was deleted', life: 3000 });
                    nodes.value = [];
                    await onInit();
                } catch (ex: any) {
                    toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured while deleting the cluster: ' + ex.message, life: 3000 });
                }
            }
        },
        reject: () => {
            //toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
    });
}

const onDeletePoolClick = (key: string, name: string) => {
    confirm.require({
        message: 'Do you want to delete the pool [' + name + ']?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
            if (key) {
                try {
                    const response = await SerialNumberPoolsService.deleteApiV1SerialNumberPools(Number(key));
                    toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Pool was deleted', life: 3000 });
                    nodes.value = [];
                    await onInit();
                } catch (ex: any) {
                    toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured while deleting the pool: ' + ex.message, life: 3000 });
                }
            }
        },
        reject: () => {
            //toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
    });
}

</script>