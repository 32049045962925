<template>
  <ConCard>
    <template #subtitle>
      Serial Number Pools
    </template>
    <template #content>
      <ConDataTable
        :tableData="serialNumberPoolsList" 
        :columns="propMatching"
        :totalItems="totalItems"
        filterDisplay="menu"
        :selectedItems="selectedItems"
        :rows="5"
        :filters="{'text': {operator: 'and', constraints: [{value: null, matchMode: 'contains'}]}, 'numeric': {operator: 'and', constraints: [{value: null, matchMode: 'equals'}]}, 'date': {operator: 'and', constraints: [{value: null, matchMode: 'dateIs'}]}, 'boolean': {operator: 'and', constraints: [{value: null, matchMode: 'equals'}]}}"
        :rowsPerPageOptions=[5,10,20,50]
        @onPage="onPage($event)"
        @onSelectionChanged="selectionChanged"
        >
        <template #available="col">
          <Column :field="col.field" :header="col.header">
            <template #body="propMatching">
              <ProgressBar :value="computePoolProgress(propMatching.data)" :class="getProgressBarClass(propMatching.data)" >
                <!-- :class="{ 'p-progressbar-value-yellow': computePoolProgress(propMatching.data)>90 }" -->
                <!-- Progressbar label syle is set to "display: none" by theme -->
              </ProgressBar>
              <span>{{ propMatching.data.rangeTo - propMatching.data.currentSerialNumber }}/{{ propMatching.data.rangeTo - propMatching.data.rangeFrom }} ({{ Math.round(computePoolProgress(propMatching.data)) }}%)</span>
            </template>
          </Column>
        </template>
        <template #isActive="col">
          <Column :field="col.field" :header="col.header">
            <template #body="propMatching">
              <i
                large
                :class="`${
                  propMatching.data.isActive ? 'pi pi-check' : 'pi pi-times'
                }`"
                :style="`color: ${
                  propMatching.data.isActive ? 'green' : 'red'
                }`"
              ></i>
            </template>
          </Column>
        </template>
        <!-- <template #number="col">
          <Column :field="col.field" :header="col.header">
            <template #body="field">
              <Button :label="field.data.link" />
            </template>
          </Column>
        </template> -->
      </ConDataTable>
      <ConToolbar id="toolbar" v-if="isAuthorized">
      <template #start>
        <div>
          <Button
            v-if="isAuthorized"
            class="p-button p-component p-button-primary mr-2" 
            :disabled="newDisabled"
            @click="onNewClick">
            <icon class="p-button-icon p-c p-button-icon-left pi pi-plus" />
            <label>New</label>
          </Button>
        </div>
        <div>
          <Button
            v-if="isAuthorized"
            class="p-button p-component p-button-primary mr-2" 
            :disabled="deleteDisabled"
            @click="onEditClick">
            <icon class="p-button-icon p-c p-button-icon-left pi pi-pencil" />
            <label>Edit</label>
          </Button>
        </div>
        <div>
          <Button
            v-if="isAuthorized"
            class="p-button p-component p-button-danger mr-2" 
            :disabled="deleteDisabled"
            @click="onDeleteClick">
            <icon class="p-button-icon p-c p-button-icon-left pi pi-trash" />
            <label>Delete</label>
          </Button>
        </div>
      </template>
    </ConToolbar>
    </template>
    <template #footer>
    </template>
  </ConCard>
</template>
<script setup lang="ts">
import { OrderTypeEnum, type SerialNumberPoolsListResponse, type SerialNumberPoolsListResponse_SerialNumberPool, SerialNumberPoolsService } from '@/shared/services/athene-api';
import { ConDataTable, ConCard } from '@congatec/primevue-components-lib';
import { type Ref, defineComponent, onMounted, ref, watch } from 'vue';
import ProgressBar from 'primevue/progressbar';
import Column from "primevue/column";
import { ConToolbar } from '@congatec/primevue-components-lib';
import Button from 'primevue/button';
// import { AuthenticationObserver } from '@/base/AuthenticationObserver';
// import { useKeycloak } from '@congatec/authentication-lib';
import { useDialog } from 'primevue/usedialog';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from "primevue/usetoast";
import { SerialNumberPoolInput } from '@/components/serial-number-pools/input';
import { onKeycloakToken } from "@congatec/authentication-lib";
import type { KeycloakService } from "@congatec/authentication-lib";

// let authenticationService = useKeycloak();
const isAuthorized: Ref<boolean> = ref(false);
// let authObserver = new AuthenticationObserver(authenticationService);
// authObserver.onLoggedIn(() => {
//   isAuthorized.value = authenticationService?.hasRole("Admin") || false;
// });

onKeycloakToken(async (authenticationService: KeycloakService) => {
  isAuthorized.value = authenticationService.hasRoles(["Admin", "SystemAdmin"], import.meta.env.VITE_KEYCLOAK_CLIENTID);
});

const dialog = useDialog();

const newDisabled = ref(false);
const deleteDisabled = ref(true);
const confirm = useConfirm();
const toast = useToast();


const props = defineProps({
  propPoolId:{
    type: Number,
    default: null
  }
});

//const refPoolId = toRef(props, 'propPoolId');
//watch(refPoolId, _fetchDataFromService);


const serialNumberPoolsList: Ref<Array<SerialNumberPoolsListResponse_SerialNumberPool>> = ref([]);

const totalItems = ref(1);
const isLoading = ref(true);
const selectedItems: any = ref([]);
const selectedPool: Ref<SerialNumberPoolsListResponse_SerialNumberPool | null> = ref(null);
const tableOptions = ref<{
  page: number;
  sortBy: Array<string>;
  sortDesc: Array<boolean>;
  groupBy: Array<string>;
  groupDesc: Array<string>;
  multiSort: boolean;
  mustSort: boolean;
  itemsPerPage: number;
}>({
  page: 1,
  sortBy: ["id"],
  sortDesc: [true],
  groupBy: [],
  groupDesc: [],
  multiSort: true,
  mustSort: false,
  itemsPerPage: 5,
});



let propMatching = [
  {
    selectionMode: "single",
    field: "selection",
    header: "",
  },
  {
    selectionMode: undefined,
    field: "id",
    header: "Id",
    dataType: "numeric",
    //isFiltered: true,
  },
  {
    selectionMode: undefined,
    field: "name",
    header: "Name",
    dataType: "text",
    //isFiltered: true,
  },
  {
    selectionMode: undefined,
    field: "description",
    header: "Description",
    dataType: "text",
    //isFiltered: true,
  },
  {
    selectionMode: undefined,
    field: "available",
    header: "Available",
    dataType: "text",
  },
  {
    selectionMode: undefined,
    field: "isActive",
    header: "Active",
    dataType: "bool",
  }
];


onMounted(async () => {
  await _fetchDataFromService();
})

function getPoolById(pool: SerialNumberPoolsListResponse_SerialNumberPool) {
  return pool.id == props.propPoolId;
}

const emit =
  defineEmits<{
    (event: "serialNumberPoolsListSelectionChanged", selectedPool: any): void;
    (event: "editClosed"): void;
  }>();

  watch(() => tableOptions, ()=>onTableOptionChange)
  function onTableOptionChange() {
    _fetchDataFromService();
  }

function selectionChanged(eventValue: any) {
  if(selectedItems.value != eventValue){
    selectedItems.value = Array(eventValue);
    console.log("selectionChanged: ",  eventValue);
    selectedPool.value = eventValue;
    deleteDisabled.value = false;
    emit(
      "serialNumberPoolsListSelectionChanged",
      selectedPool.value
    );
  }
}

const onPage = (event: any) => {
    //event.page: New page number
    //event.first: Index of first record
    //event.rows: Number of rows to display in new page
    //event.pageCount: Total number of pages
    //console.log("OnPage: ", event);
    if(typeof event === "number") {
      //console.log("Page event object is a number")
      if(event <= 0) {
        event = 1;
      }
      tableOptions.value.page = event;
    }
    else {
      tableOptions.value.itemsPerPage = event.rows;
      tableOptions.value.page = event.page+1;
    }

    _fetchDataFromService();
}

  async function _fetchDataFromService() {
    const { page, itemsPerPage, sortBy, sortDesc } = tableOptions.value;
    const request = {
      //sieveModelFilters: sieveFilterStatements,
      sieveModelSorts: undefined, // is a comma-delimited ordered list of property names to sort by. Adding a - before the name switches to sorting descendingly.
      sieveModelPage: page,
      sieveModelPageSize: itemsPerPage,
      //filterStatements: filterStatements,
      pageNumber: page,
      pageSize: itemsPerPage,
      orderingFields: sortBy.map((val, i) => {
        return {
          orderBy: val.replace(/\./g, ""),
            orderType: sortDesc[i] == true ? OrderTypeEnum.DESCENDING : OrderTypeEnum.ASCENDING,
            orderingPosition: i,
        };
      }),
    };

    if (request && tableOptions) {
      isLoading.value = true;

      SerialNumberPoolsService.getApiV1SerialNumberPools(
          false,
          false,
          request.pageNumber,
          request.pageSize
        )
        .then((r: any) => {
          console.log("RESPONSE: ", r);
          const response: SerialNumberPoolsListResponse = r;

          if (response?.serialNumberPools) {
            console.log("POOLS: ", response.serialNumberPools);
            serialNumberPoolsList.value = response.serialNumberPools ?? [];
            totalItems.value = Number(response.totalCount);

            const preSelectedPool = serialNumberPoolsList.value.filter(getPoolById)
            if(preSelectedPool[0]) {
              console.log("preSelectedPool: ", preSelectedPool);
              selectedPool.value = preSelectedPool[0];
              selectedItems.value = preSelectedPool;
            }
          }

          isLoading.value = false;
        });
    }
  }

  function computePoolProgress(data: SerialNumberPoolsListResponse_SerialNumberPool ) {
    if(data.rangeTo !== undefined && data.rangeFrom !== undefined && data.currentSerialNumber !== undefined) {
      return(100/(data.rangeTo - data.rangeFrom))*(data.rangeTo - data.currentSerialNumber);
    }
    return 0;
  }

  function getProgressBarClass(data: SerialNumberPoolsListResponse_SerialNumberPool) {
    let progress = computePoolProgress(data);
    if(progress > 30)
      return 'greenbar';
    else if(progress <= 30 && progress > 10)
      return 'yellowbar';
    else return 'redbar';
  }

  const onNewClick = () => {
  newDisabled.value = true;
  dialog.open(SerialNumberPoolInput, {
      data: {
      },
        onClose: onNewClosed,
        props: {
            header: "New Serial Number Pool",
            style: {width: '50vw'}                
        },
      }
    );
}

const onNewClosed = async (_options: any) => {
  newDisabled.value = false;
  //forceSerialNumberPoolsListRerender();
  await onPage(1);
}

const onEditClick = () => {
  deleteDisabled.value = true;
  dialog.open(SerialNumberPoolInput, {
      data: {
        poolId: selectedPool.value?.id
      },
        onClose: onEditClosed,
        props: {
            header: "Edit Serial Number Pool " + selectedPool.value?.name,
            style: {width: '50vw'}                
        },
      }
    );
}

const onEditClosed = async (_options: any) => {
  deleteDisabled.value = false;
  //forceSerialNumberPoolsListRerender();
  //forceSerialNumberPoolsDetailsRerender();
  await onPage(1);
  emit(
    "editClosed"
  );
}

const onDeleteClick = () => {
  deleteDisabled.value = true;
  confirm.require({
      message: 'Do you want to delete the Pool with Id [' + selectedPool.value?.id?.toString() + ']?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClass: 'p-button-danger',
      accept: async () => {
        if(selectedPool.value?.id) {
          try {
            const response = await SerialNumberPoolsService.deleteApiV1SerialNumberPools(selectedPool.value.id)
            toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Pool was deleted', life: 3000 });
            selectedPool.value = null;
            //forceSerialNumberPoolsListRerender();
            await onPage(1);
          } catch(ex: any) {
            toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured while deleting the pool: ' + ex.message, life: 3000 }); 
            deleteDisabled.value = false;
          }
        }
      },
      reject: () => {
          toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
          deleteDisabled.value = false;
      }
  });
}
</script>


<script lang="ts">
export default defineComponent({
  name: "SerialNumberPoolsListView",
});

export enum SerialNumberPoolsListViewEvents {
  SerialNumberPoolsListSelectionChanged = "SerialNumberPoolsListSelectionChanged",
  EditClosed = "EditClosed",
}
</script>

<style lang="css"> 

.greenbar.p-progressbar .p-progressbar-value {
  background: green !important;

}

.yellowbar.p-progressbar .p-progressbar-value {
  background: yellow !important;
}

.redbar.p-progressbar .p-progressbar-value {
  background: red !important;
}

</style>
