/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductionOrderPdfsResponse } from '../models/ProductionOrderPdfsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductionOrdersService {

    /**
     * @param subDirectory 
     * @returns ProductionOrderPdfsResponse Success
     * @throws ApiError
     */
    public static getApiV1ProductionOrdersRevisionsPdfsCheckout(
subDirectory: string,
): CancelablePromise<ProductionOrderPdfsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ProductionOrders/revisions/{subDirectory}/pdfs/checkout',
            path: {
                'subDirectory': subDirectory,
            },
        });
    }

}
