<template>
    <div class="m-3">
        <form @submit.prevent="onSubmit()" class="flex flex-column gap-3 p-fluid">
            <ConMessages 
                v-if="v$.name.$invalid && submitted"
                severity="error">
                <template #messageContent>
                    {{ v$.name.$errors[0].$message }}
                </template>
            </ConMessages>
            <span class="p-float-label">
                <InputText id="name" v-model="v$.name.$model" :disabled="paramPoolId" />
                <label for="name">Name</label>
            </span>
            <span class="p-float-label">
                <InputText id="description" v-model="state.description" />
                <label for="description">Description</label>
            </span>
            <ConMessages 
                v-if="v$.currentSerialNumber.$invalid && submitted"
                severity="error">
                <template #messageContent>
                    {{ v$.currentSerialNumber.$errors[0].$message }}
                </template>
            </ConMessages>
            <span class="p-float-label">
                <InputNumber id="currentSerialNumber" v-model="v$.currentSerialNumber.$model" />
                <label for="currentSerialNumber">Current Serial Number</label>
            </span>
            <ConMessages 
                v-if="v$.rangeFrom.$invalid && submitted"
                severity="error">
                <template #messageContent>
                    {{ v$.rangeFrom.$errors[0].$message }}
                </template>
            </ConMessages>
            <span class="p-float-label">
                <InputNumber id="rangeFrom" v-model="v$.rangeFrom.$model" />
                <label for="rangeFrom">Range From</label>
            </span>
            <ConMessages 
                v-if="v$.rangeTo.$invalid && submitted"
                severity="error">
                <template #messageContent>
                    {{ v$.rangeTo.$errors[0].$message }}
                </template>
            </ConMessages>
            <span class="p-float-label">
                <InputNumber id="rangeTo" v-model="v$.rangeTo.$model" />
                <label for="rangeTo">Range To</label>
            </span>
            <ConMessages 
                v-if="v$.maxRequestsPerDay.$invalid && submitted"
                severity="error">
                <template #messageContent>
                    {{ v$.maxRequestsPerDay.$errors[0].$message }}
                </template>
            </ConMessages>
            <span class="p-float-label">
                <InputNumber id="maxRequestsPerDay" v-model="state.maxRequestsPerDay" />
                <label for="maxRequestsPerDay">Max Requests per Day</label>
            </span>
            <ConMessages 
                v-if="v$.maxSerialNumbersByRequest.$invalid && submitted"
                severity="error">
                <template #messageContent>
                    {{ v$.maxSerialNumbersByRequest.$errors[0].$message }}
                </template>
            </ConMessages>
            <span class="p-float-label">
                <InputNumber id="maxSerialNumbersByRequest" v-model="state.maxSerialNumbersByRequest" />
                <label for="maxSerialNumbersByRequest">Max Serial Numbers per Request</label>
            </span>
            <span class="p-float-label">
                <InputNumber id="notificationThershold" v-model="state.notificationTheshold" inputId="minmax" :min="0" :max="100" />
                <label for="notificationThershold">Notification Theshold (in %)</label>
            </span>
            <div>Active</div>
            <div>
                <InputSwitch v-model="state.active" class="ml-3" />
            </div>
            <Button type="submit" label="submit"></Button>
        </form>
    </div>
</template>

<script setup lang="ts">
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import { inject, ref } from 'vue';
import { defineComponent } from "vue";
import Button from 'primevue/button';
import { useVuelidate } from '@vuelidate/core'
import { required, minValue, integer, helpers } from '@vuelidate/validators'
import { ConMessages } from '@congatec/primevue-components-lib';
import { SerialNumberPoolsService, type SerialNumberPoolsCreateRequest, type SerialNumberPoolsCreateResponse, type SerialNumberPoolsUpdateRequest, type SerialNumberPoolsUpdateResponse } from '@/shared/services/athene-api';
import { useToast } from "primevue/usetoast";
import { AuthenticationObserver } from '@/base/AuthenticationObserver';
import { useKeycloak } from "@congatec/authentication-lib";
import { useConfirm } from 'primevue/useconfirm';

// const name = ref(null);
// const description = ref(null);
// const currentSerialNumber = ref(null);
// const rangeFrom = ref(null);
// const rangeTo = ref(null);
// const maxRequestsPerDay = ref(null);
// const maxRequestsPerDay = ref(null);
// const maxSerialNumbersByRequest = ref(null);
// const active = ref(true);

const confirm = useConfirm();
let authenticationService = useKeycloak();
let authObserver = new AuthenticationObserver(authenticationService);

const props = withDefaults(defineProps<Props>(), {});
const dialogRef: any = inject('dialogRef');
let paramPoolId = props.poolId || dialogRef.value.data?.poolId;

let submitted = ref(false);
const toast = useToast();

const state = ref({    
    name: '',
    description: '',
    currentSerialNumber: 0,
    rangeFrom: 0,
    rangeTo: 1,
    maxRequestsPerDay: 1,
    maxSerialNumbersByRequest: 1,
    active: true,
    notificationTheshold: 0
})

const myBetween = helpers.withParams({ }, (value: number) => value >= state.value.rangeFrom && value <= state.value.rangeTo);
const lteCurrentSnr = (value: number) => value <= state.value.currentSerialNumber && value < state.value.rangeTo;
const gteCurrentSnr = (value: number) => value >= state.value.currentSerialNumber && value > state.value.rangeFrom;
// const gteRangeFrom = (value: number) => value >= state.value.rangeFrom;



const rules = {
    name: { required },
    //description: { required },
    currentSerialNumber: { required, integer, between: helpers.withMessage(() => `Must be between "Range From" (${state.value.rangeFrom}) and "Range To" (${state.value.rangeTo})`, myBetween ), $lazy: true },
    rangeFrom: { required, integer, lteCurrentSnr: helpers.withMessage(() => `Must be lower than or equal to "Current Serial Number" (${state.value.currentSerialNumber}) and lower than "Range To" (${state.value.rangeTo})`, lteCurrentSnr )},
    rangeTo: { required, integer, gteCurrentSnr:  helpers.withMessage(() => `Must be greater than or equal to "Current Serial Number" (${state.value.currentSerialNumber}) and greater than "Range From" (${state.value.rangeFrom})`, gteCurrentSnr ), $lazy: true },
    maxRequestsPerDay: { required, integer, minValue: minValue(1) },
    maxSerialNumbersByRequest: { required, integer, minValue: minValue(1) }
}


const v$ = useVuelidate(rules, state, {$lazy: true});

const onSubmit = async () => {
    submitted.value = true;
    console.log("Validate", v$);
    await v$.value.$validate() // checks all inputs
    if(v$.value.$error) {
        return;
    }
    console.log("Submit", state.value);

    
    if(paramPoolId) {
        try {
            const request: SerialNumberPoolsUpdateRequest = {
                name: state.value.name,
                description: state.value.description,
                rangeFrom: state.value.rangeFrom,
                rangeTo: state.value.rangeTo,
                currentSerialNumber: state.value.currentSerialNumber,
                maxRequestsPerDay: state.value.maxRequestsPerDay,
                maxSerialNumbersByRequest: state.value.maxSerialNumbersByRequest,
                isActive: state.value.active,
                notificationThreshold: state.value.notificationTheshold,
                isDeleted: false
            }
            const response = await submitUpdateItem(request);
            toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Pool was updated', life: 3000 });
            dialogRef.value.close();
        } catch(ex: any) {
            console.log("Error: SerialNumberPool could not be updated: ", ex.body.Errors[0].ErrorMessage);
            toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured while updating the pool: ' + ex.body.Errors[0].ErrorMessage, life: 3000 });
        }
    } 
    else {
        let existingPool: any;
        try {
            existingPool = await SerialNumberPoolsService.getApiV1SerialNumberPools2(state.value.name);            
        }
        catch {
            existingPool = null;
        }
        if(existingPool) {
            if(existingPool.isDeleted) {
                confirm.require({
                    message: 'The Pool with Name "[' + existingPool.name + ']" was already deleted. Do you want to restore it?',
                    header: 'Restore Confirmation',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-info',
                    accept: async () => {
                        try {
                            paramPoolId = existingPool.id;
                            const request: SerialNumberPoolsUpdateRequest = {
                                name: existingPool.name,
                                description: existingPool.description,
                                rangeFrom: existingPool.rangeFrom,
                                rangeTo: existingPool.rangeTo,
                                currentSerialNumber: existingPool.currentSerialNumber,
                                maxRequestsPerDay: existingPool.maxRequestsPerDay,
                                maxSerialNumbersByRequest: existingPool.maxSerialNumbersByRequest,
                                isActive: existingPool.isActive,
                                notificationThreshold: existingPool.notificationThershold,
                                isDeleted: false
                            }
                            const response = await submitUpdateItem(request);
                            toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Pool was restored', life: 5000 });
                            dialogRef.value.close();
                        } catch(ex: any) {
                            console.log("Error: SerialNumberPool could not be recreated: ", ex.body.Errors[0].ErrorMessage);
                            toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured while restoring the pool: ' + ex.body.Errors[0].ErrorMessage, life: 3000 });
                        }
                    },
                    reject: async () => {
                        toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                    }
                });

                
            }
            else {
                console.log("Error: SerialNumberPool could not be created: ", "The Serial Number Pool '[" + state.value.name + "]' already exists");
                toast.add({ severity: 'error', summary: 'Error', detail: "An error occured while updating the pool: The Serial Number Pool '[" + state.value.name + "]' already exists", life: 3000 });
            }
        }
        else {
            try { 
                const response = await submitNewItem();
                toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Pool was created', life: 3000 });
                dialogRef.value.close();
            } catch(ex: any) {
                console.log("Error: SerialNumberPool could not be created: ", ex.body.Errors[0].ErrorMessage);
                toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured while creating the pool: ' + ex.body.Errors[0].ErrorMessage, life: 3000 });
        }
        }
    }


    submitted.value = false;
}

const submitNewItem = async () => {
    const request: SerialNumberPoolsCreateRequest = {
        name: state.value.name,
        description: state.value.description,
        rangeFrom: state.value.rangeFrom,
        rangeTo: state.value.rangeTo,
        currentSerialNumber: state.value.currentSerialNumber,
        maxRequestsPerDay: state.value.maxRequestsPerDay,
        maxSerialNumbersByRequest: state.value.maxSerialNumbersByRequest,
        isActive: state.value.active,
        notificationThreshold: state.value.notificationTheshold
    }
    const response: SerialNumberPoolsCreateResponse = await SerialNumberPoolsService.postApiV1SerialNumberPools(request);
    return response;
}

const submitUpdateItem = async (request: SerialNumberPoolsUpdateRequest) => {
    
    const response: SerialNumberPoolsUpdateResponse = await SerialNumberPoolsService.putApiV1SerialNumberPools(paramPoolId, request);
    return response;
}

authObserver.onLoggedIn(async () => {
    // now we are logged in!

    if (paramPoolId) {
        await fetchExistingData(paramPoolId);        
    }
});

const fetchExistingData = async (id: number) => {
    const item = await SerialNumberPoolsService.getApiV1SerialNumberPools1(id);
    state.value.name = item.name ?? "";
    state.value.description = item.description ?? "";
    state.value.currentSerialNumber = item.currentSerialNumber ?? 0;
    state.value.rangeFrom = item.rangeFrom ?? 0;
    state.value.rangeTo = item.rangeTo ?? 1;
    state.value.maxRequestsPerDay = item.maxRequestsPerDay ?? 1;
    state.value.maxSerialNumbersByRequest = item.maxSerialNumbersByRequest ?? 1;
    state.value.active = item.isActive ?? true;
    state.value.notificationTheshold = item.notificationThreshold ?? 0;
}

</script>

<script lang="ts">

export type Props = {
    poolId?: number
} 

export default defineComponent({
    name: "SerialNumberPoolInput",
    components: { ConMessages },
});


</script>