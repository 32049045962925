<template>
  <ConfirmDialog />
  <InputSettings :visible="showCreateSettingDialog" :on-hide="(created: any) => {
    showCreateSettingDialog = false;
    reloadSettingsTableData();
  }" />
    <InputSettings :visible="showEditSettingDialog" :setting="selectedSetting[0]" :on-hide="(edited: any) => {
    showEditSettingDialog = false;
    reloadSettingsTableData();
  }" />
    <div class="card">
        <div class="mt-3"></div>
        <ConToolbar v-if="isAuthorized">
            <template #start>
                <div class="flex flex-row gap-3">
                    <Button label="Create" @click="onCreate()" icon="pi pi pi-plus" class="p-button p-component p-button-primary mr-2" >
                    </Button>
                    <Button label="Edit" @click="onEdit()" icon="pi pi-pencil" :disabled="editDisabled" class="p-button p-component p-button-primary mr-2" >
                    </Button>
                    <Button label="Delete" @click="confirmDelete()" class="p-button p-component p-button-danger mr-2" icon="pi pi-trash" :disabled="deleteDisabled">
                    </Button>
                </div>
            </template>
        </ConToolbar>
    </div>
    <div class="mt-3"></div>
    <div>
        <ConDataTable v-if="isAuthorized" tableId="SettingsList" title="Configurations" :columns="configurationTableColumns"
            filterDisplay="menu" :rowsPerPageOptions="[10, 20, 50]" :onReload="fetchSettingsDataAsync"
            @onSelectionChanged="handleSettingsSelectionChange" ref="settingsTableRef">
            
            <!-- <template #screeningStatus="col">
        <Column :field="col.field" :header="col.header">

          <template #body="field">
            <div class="flex justify-content-center">
              <i v-if="field.data[col.field] == 'notListed'" v-tooltip="'This visitor is not listed'"
                class="pi pi-thumbs-up-fill " style="color: rgb(23, 201, 100)" aria-label="screen success"></i>
              <i v-if="field.data[col.field] == 'listed'" v-tooltip="'This visitor is listed!'" class=" pi
              pi-thumbs-down-fill " style=" color: rgb(242, 19, 93)" aria-label="screen failed"></i>
              <i v-if="field.data[col.field] == 'inReview'" v-tooltip="'Review pending'" class="pi pi-spinner pi-spin"
                style="color: rgb(255, 130, 0)" aria-label="screen pending"></i>
            </div>
          </template>
        </Column>

      </template> -->
        </ConDataTable>
    </div>
</template>
<script setup lang="ts">
import { ConDataTable } from '@congatec/primevue-components-lib';
import { type Ref, ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';
import Button from 'primevue/button';
import { ConToolbar } from '@congatec/primevue-components-lib';
// import Column from "primevue/column";
import { SettingsService, type SettingsListResponse_Settings } from '@/shared/services/athene-api';
// import { useDialog } from 'primevue/usedialog';
import { InputSettings } from '@/components/settings';
import { onKeycloakToken } from "@congatec/authentication-lib";
import type { KeycloakService } from "@congatec/authentication-lib";

// const toast = useToast();
const isAuthorized: Ref<boolean> = ref(false);
const selectedSetting = ref<SettingsListResponse_Settings[]>([]);
const settingsIdList = ref<number[]>([]);
const settingsTableRef = ref<null | typeof ConDataTable>(null);
// const dialog = useDialog();
const showCreateSettingDialog = ref<boolean>(false);
const showEditSettingDialog = ref<boolean>(false);
const editDisabled = ref(true);
const deleteDisabled = ref(true);

const configurationTableColumns = [
    {
        selectionMode: 'multiple',
        field: 'any'
    },
    //   {
    //     field: "Id",
    //     header: "Visitor Id",
    //     dataType: "text",
    //     isFiltered: true,
    //     sortable: true,
    //   },
    {
        field: "key",
        header: "Key",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    {
        field: "value",
        header: "Value",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    }
];

onKeycloakToken(async (authenticationService: KeycloakService) => {
  isAuthorized.value = authenticationService.hasRoles(["Admin", "SystemAdmin"], import.meta.env.VITE_KEYCLOAK_CLIENTID);
});

function handleSettingsSelectionChange(selectedRows: any) {
    console.log("handleSel", selectedRows)
    console.log("settingsIdList: ", settingsIdList)
    selectedSetting.value = selectedRows;
    settingsIdList.value = [];
    selectedSetting.value.forEach(setting => {
        settingsIdList.value.push(setting.id ? setting.id : 0)
    });

    if(selectedSetting.value.length != 1) {
      editDisabled.value = true;
    }
    else {
      editDisabled.value = false;
    }
    if(selectedSetting.value.length > 0) {
      deleteDisabled.value = false;
    }
    else {
      deleteDisabled.value = true;
    }

}

async function fetchSettingsDataAsync(filters?: string, sorts?: string, page?: number, rows?: number): Promise<any> {

    let res = await SettingsService.getApiV1SettingsList(page, rows);
    console.log("Resolution: ", res)
    return {
        totalCount: res.totalCount,
        data: res.settings,
    }
}


const onCreate = () => {
  showCreateSettingDialog.value = true;
}


// async function onCreate(): Promise<any> {
//   const request: SettingsCreateRequest = {
//     visitIds: visitIdList.value
//   }
//   await SettingsService.postApiV1Settings(request);
//   toast.add({ severity: 'success', summary: 'Successful', detail: 'Successful Check In/Check Out', life: 3000 });
//   visitIdList.value = [];
//   selectedSetting.value = [];
//   console.log("selectedItems", selectedSetting.value)
//   configurationTableRef.value?.forceReload();
// }

async function onEdit(): Promise<any> {
  showEditSettingDialog.value = true;
  // const request: CreateCheckInOutRequest = {
  //   visitIds: visitIdList.value
  // }
  //await CheckInOutService.putApiV1CheckInOutCreateOrEdit(request);
  // toast.add({ severity: 'success', summary: 'Successful', detail: 'Successful Check In/Check Out', life: 3000 });
  // visitIdList.value = [];
  // selectedSetting.value = [];
  // console.log("selectedItems", selectedSetting.value)
  // settingsTableRef.value?.forceReload();
}

const confirm = useConfirm();
const confirmDelete = () => {
  confirm.require({
    header: 'Delete Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    //TODO: implement option to see a list of items that will be delted
    message: 'Are you sure you want to delete this Item(s)',
    accept: async () => {
      try {
        await SettingsService.deleteApiV1SettingsDelete({ settingsIds: settingsIdList.value });
        
        toast.add({ severity: 'success', summary: 'Delete', detail: 'Item(s) deleted', life: 3000 });
        selectedSetting.value = [];
        reloadSettingsTableData();
      } catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error!', detail: 'An error occured while deleting: ' + ex.message, life: 3000 });
      }
    },
    reject: () => {
        toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }
  })
}
const toast = useToast();

function reloadSettingsTableData() {
  settingsTableRef.value?.forceReload();
}



</script>