/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PoolClusterCreateRequest } from '../models/PoolClusterCreateRequest';
import type { PoolClusterCreateResponse } from '../models/PoolClusterCreateResponse';
import type { PoolClusterDeleteResponse } from '../models/PoolClusterDeleteResponse';
import type { PoolClusterDetailsResponse } from '../models/PoolClusterDetailsResponse';
import type { PoolClusterListResponse } from '../models/PoolClusterListResponse';
import type { PoolClusterUpdateRequest } from '../models/PoolClusterUpdateRequest';
import type { PoolClusterUpdateResponse } from '../models/PoolClusterUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PoolClusterService {

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @param deleted 
     * @param includeDeleted 
     * @returns PoolClusterListResponse Success
     * @throws ApiError
     */
    public static getApiV1PoolCluster(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
deleted?: boolean,
includeDeleted?: boolean,
): CancelablePromise<PoolClusterListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/PoolCluster',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
                'Deleted': deleted,
                'IncludeDeleted': includeDeleted,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns PoolClusterCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1PoolCluster(
requestBody?: PoolClusterCreateRequest,
): CancelablePromise<PoolClusterCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/PoolCluster',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param clusterId 
     * @returns PoolClusterDetailsResponse Success
     * @throws ApiError
     */
    public static getApiV1PoolCluster1(
clusterId: number,
): CancelablePromise<PoolClusterDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/PoolCluster/{clusterId}',
            path: {
                'clusterId': clusterId,
            },
        });
    }

    /**
     * @param clusterId 
     * @param requestBody 
     * @returns PoolClusterUpdateResponse Success
     * @throws ApiError
     */
    public static putApiV1PoolCluster(
clusterId: number,
requestBody?: PoolClusterUpdateRequest,
): CancelablePromise<PoolClusterUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/PoolCluster/{clusterId}',
            path: {
                'clusterId': clusterId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param clusterId 
     * @returns PoolClusterDeleteResponse Success
     * @throws ApiError
     */
    public static deleteApiV1PoolCluster(
clusterId: number,
): CancelablePromise<PoolClusterDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/PoolCluster/{clusterId}',
            path: {
                'clusterId': clusterId,
            },
        });
    }

}
