/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DepartmentListResponse } from '../models/DepartmentListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DepartmentService {

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns DepartmentListResponse Success
     * @throws ApiError
     */
    public static getApiV1Department(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<DepartmentListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Department',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

}
