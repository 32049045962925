<template>
    <ConCard>
        <template #subtitle>
            Serial Number Pool-Cluster
        </template>
        <template #content>
            <ConDataTable tableId="clusterTable" :columns="columns" filterDisplay="menu" :rowsPerPageOptions="[10, 20, 50]"
                :selection="selectedItems" :onReload="fetchPoolClusterDataAsync" @onSelectionChanged="handleSelectionChange"
                ref="clusterTableRef" expansion-key="serialNumberPools" :expansion-columns="expansionColumns">

            </ConDataTable>
        </template>
    </ConCard>
</template>
<script setup lang="ts">
import { PoolClusterService } from '../../shared/services/athene-api';
import { ConDataTable, ConCard } from '@congatec/primevue-components-lib';
import { type Ref, ref } from 'vue';
// import { AuthenticationObserver } from '@/base/AuthenticationObserver';
// import { useKeycloak } from '@congatec/authentication-lib';
// import { SerialNumberPoolInput } from '@/components/serial-number-pools/input';
import { useDialog } from 'primevue/usedialog';
import { onKeycloakToken } from "@congatec/authentication-lib";
import type { KeycloakService } from "@congatec/authentication-lib";

const isAuthorized: Ref<boolean> = ref(false);

onKeycloakToken(async (authenticationService: KeycloakService) => {
    isAuthorized.value = authenticationService.hasRoles(["Admin", "SystemAdmin"], import.meta.env.VITE_KEYCLOAK_CLIENTID);
});

const dialog = useDialog();
const clusterTableRef = ref<null | typeof ConDataTable>(null);
const selectedItems: Ref<any[]> = ref([]);

let columns = [
    {
        expander: true,
        field: 'expansion',
        header: ''
    },
    {
        selectionMode: "single",
        field: "select",
    },
    {
        field: 'name',
        header: 'Name',
    },
    {
        field: 'description',
        header: 'Description',
    }
];


let expansionColumns = [
    // {
    //     selectionMode: "single",
    //     field: "selection",
    //     header: "",
    // },
    {
        selectionMode: undefined,
        field: "id",
        header: "Id",
        dataType: "numeric",
        //isFiltered: true,
    },
    {
        selectionMode: undefined,
        field: "name",
        header: "Name",
        dataType: "text",
        //isFiltered: true,
    },
    {
        selectionMode: undefined,
        field: "description",
        header: "Description",
        dataType: "text",
        //isFiltered: true,
    },
    {
        selectionMode: undefined,
        field: "available",
        header: "Available",
        dataType: "text",
    },
    {
        selectionMode: undefined,
        field: "isActive",
        header: "Active",
        dataType: "bool",
    }
];

const emit =
    defineEmits<{
        (event: "serialNumberPoolsListSelectionChanged", selectedPool: any): void;
        (event: "editClosed"): void;
    }>();

function handleSelectionChange(eventValue: any) {
    if (selectedItems.value != eventValue) {
        selectedItems.value = Array(eventValue);
        console.log("selectionChanged: ", eventValue);
        // selectedPool.value = eventValue;
        // deleteDisabled.value = false;
        emit(
            "serialNumberPoolsListSelectionChanged",
            //selectedPool.value
            selectedItems.value[0]
        );
    }
}

const fetchPoolClusterDataAsync = async (filters?: string, sorts?: string, page?: number, rows?: number) => {

    let res = await PoolClusterService.getApiV1PoolCluster(filters, sorts, page, rows,)
    return {
        totalCount: res.totalCount,
        data: res.poolCluster,
    }
}

</script>