<script setup lang="ts">
import { RouterView, useRoute } from "vue-router";
import {
  ConThemeSwitcher,
  ConSidebar,
  ConToolbar,
  ConUserButton,
  ConSearch,
  type ConSidebarPropsGroupType,
} from "@congatec/primevue-components-lib";
import { type Ref, watch, ref } from 'vue';
import { useBreadCrumbStore, useThemeStore, ConFileDownloadStatus } from "@congatec/primevue-components-lib";
import { Theme } from "@congatec/primevue-components-lib";
import type { MenuItem } from "primevue/menuitem";
import Toast from 'primevue/toast';
import { onKeycloakToken } from "@congatec/authentication-lib";
import type { KeycloakService } from "@congatec/authentication-lib";

const store = useBreadCrumbStore();
const isAuthorized: Ref<boolean> = ref(false);
const isClusterManagement: Ref<boolean> = ref(false);
// onMounted(() => {
//   store.add({label: "Sample", to: "/test"}); 
// });  
const themeStore = useThemeStore();
const theme = ref();
const useTheme = () => {
  if (themeStore.state == Theme.ConDark) {
    theme.value = new URL("../node_modules/@congatec/primevue-components-lib/dist/lib/theme_dark.css", import.meta.url).href;
  } else {
    theme.value = new URL("../node_modules/@congatec/primevue-components-lib/dist/lib/theme_light.css", import.meta.url).href;
  }
}
useTheme();

themeStore.$subscribe(useTheme);

const route = useRoute();
watch(
  () => route.path, () => {
    store.setTo(route.meta.breadcrumb as [MenuItem]); 
  }
)



const onSearchUpdate = (input: string | undefined) => {
  console.log("Search term: ", input);
}

const onSearchEnter = (input: string | undefined) => {
  console.log("Search enter: ", input);
}
let sidebarItems: Ref<ConSidebarPropsGroupType[]> = ref([]);
onKeycloakToken(async (authenticationService: KeycloakService) => {
  isAuthorized.value = authenticationService.hasRoles(["Admin", "SystemAdmin"], import.meta.env.VITE_KEYCLOAK_CLIENTID);
  isClusterManagement.value = authenticationService.hasRoles(["ClusterManagement"], import.meta.env.VITE_KEYCLOAK_CLIENTID);

  sidebarItems.value = [
  {
    group: "Favorites",
    items: [
      { icon: "pi pi-home", label: "Home", to: "/" },
      {
        label: "Serial Number Reservation",
        icon: "pi pi-plus",
        to: "/serial-number-reservation"
      },
      {
        label: "Production Orders Download",
        icon: "pi pi-download",
        to: "/production-orders/pdfs/download"
      },
      {
        label: "Admin",
        icon: "pi pi-cog",
        visible: isAuthorized.value || isClusterManagement.value,
        items: [
          {
            label: "Settings",
            icon: "pi pi-sliders-v",
            to: "/Settings",
            visible: isAuthorized.value
          },
          {
            label: "Manage Pool Cluster",
            icon: "pi pi-sitemap",
            to: "/admin",
            visible: isAuthorized.value || isClusterManagement.value
          },
        ]
      },
      
    ],
  },
];
});

</script>

<template>
  <div>
    <Toast />
    <link rel="stylesheet" :href="theme" />
    <ConSidebar :items="sidebarItems">
      <ConToolbar :home="{ label: 'Home', to: '/' }"  >
        <template #start>
        <ConSearch 
            @update="onSearchUpdate"
            @enter="onSearchEnter"
          />
        </template>
        <template #end>
          <ConThemeSwitcher />
          <ConUserButton />
        </template>
        <RouterView class="m-3" />
      </ConToolbar>
    </ConSidebar>
    <ConFileDownloadStatus>
    </ConFileDownloadStatus>
  </div>
</template>
