<template>
  <div class="w-full h-full flex flex-column">
    <DynamicDialog />
    <ConfirmDialog />

    <div class="w-full h-full flex flex-column gap-3">
      <div class="w-full flex flex-row gap-2">
        <PoolClusterList @serialNumberPoolsListSelectionChanged="serialNumberPoolSelectionChanged" class="w-6">

        </PoolClusterList>

        <SerialNumberReservationCreateView :propPool="selectedPool"
          @SerialNumberReservationCreateSuccess="serialNumberReservationCreated" class="w-6">
        </SerialNumberReservationCreateView>

      </div>
      <div class="w-full flex flex-row gap-2">
        <div class="w-6 flex flex-column">


          

          <div v-for="pool in selectedPool?.serialNumberPools" class="w-full flex flex-row gap-2">
            <SerialNumberPoolsDetailsView :key="SerialNumberPoolsDetailsKey" :propPool="pool" class="w-full">

            </SerialNumberPoolsDetailsView>
          </div>
        </div>
        <div class="w-6 flex flex-column">
          <SerialNumberReservationExportXlsxView v-if="reservationIds && reservationIds?.length > 0"
            :propReservationId="reservationIds[0]">
          </SerialNumberReservationExportXlsxView>
          <!-- v-if="reservationId" -->
          <div v-for="reservationId in reservationIds" class="w-full flex flex-row gap-2">
            <SerialNumberReservationDetailsView :propReservationId="reservationId" class="w-full">
            </SerialNumberReservationDetailsView>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script setup lang="ts">
import { SerialNumberPoolsListView, SerialNumberPoolsDetailsView } from '@/components/serial-number-pools';
import { SerialNumberReservationCreateView, SerialNumberReservationDetailsView } from '@/components/serial-number-reservations';
import { type Ref, defineComponent, ref } from 'vue';
import ConfirmDialog from 'primevue/confirmdialog';
import DynamicDialog from 'primevue/dynamicdialog';
import { PoolClusterList } from '@/components/pool-cluster';
import { SerialNumberReservationExportXlsxView } from '@/components/serial-number-reservations';

const SerialNumberPoolsListKey = ref(0);
const SerialNumberPoolsDetailsKey = ref(0)
const selectedPool: Ref<any | undefined> = ref();
const reservationIds: Ref<Array<number> | null> = ref(null);

const forceSerialNumberPoolsListRerender = () => {
  SerialNumberPoolsListKey.value += 1;
}

const forceSerialNumberPoolsDetailsRerender = () => {
  SerialNumberPoolsDetailsKey.value += 1;
}

function serialNumberPoolSelectionChanged(event: any) {
  selectedPool.value = event;
  reservationIds.value = null;
  console.log("Dashboard serialNumberPoolSelectionChanged", selectedPool.value);
}

function serialNumberReservationCreated(paramReservationId: any) {
  reservationIds.value = paramReservationId;
  var oldPool = selectedPool;
  if (oldPool !== null) {
    selectedPool.value = oldPool.value;
  }
  // tell SerialNumberPoolsListView to rerender itself and reload it's data
  forceSerialNumberPoolsListRerender();
  console.log("Dashboard serialNumberReservationCreated", reservationIds.value);
}


</script>

<script lang="ts">
export default defineComponent({
  name: "SerialNumberReservationDashboardView",
});
</script>

<style lang="scss" scoped></style>