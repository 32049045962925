import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";

import PrimeVue from "primevue/config";
import "primeflex/primeflex.css"; 
import "primevue/resources/primevue.min.css"
import 'primeicons/primeicons.css';
// import "../src/themes/md-light-indigo/theme.css";
import { createKeycloak, getActiveKeycloak } from "@congatec/authentication-lib";
import { createConComponents } from "@congatec/primevue-components-lib";
import { OpenAPI } from "./shared/services/athene-api";
import axios from "axios";
import ToastService from 'primevue/toastservice';
import DialogService from 'primevue/dialogservice';
import ConfirmationService from 'primevue/confirmationservice';

OpenAPI.BASE = import.meta.env.VITE_ATHENE_BASE_URL || "";

const pinia = createPinia();
const keycloak = createKeycloak({
    config: {
      url: import.meta.env.VITE_KEYCLOAK_URL,
      realm: import.meta.env.VITE_KEYCLOAK_REALM,
      clientId: import.meta.env.VITE_KEYCLOAK_CLIENTID,
    },
    init: {
      onLoad: 'login-required',
    },
    pinia,
    axios
});

const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(PrimeVue);

app.use(keycloak);
app.use(createConComponents({
  pinia, 
  keycloak: getActiveKeycloak() as any
}));
app.use(ToastService)
app.use(DialogService);
app.use(ConfirmationService);
app.mount("#app");
