<template>
  <ConCard>
    <!-- <template #header>
      Header
    </template>
    <template #title>
      Advanced Card
    </template> -->
    <template #subtitle>
      Serial Number Reservation
    </template>
    <template #content>
      <span class="p-float-label">
        <InputNumber inputId="amount" v-model="model.amount" showButtons :min="0" style="width: 100%" />
        <label for="amount">Amount</label>
      </span>
      <br />
      <span class="p-float-label">
        <Textarea inputId="description" v-model="(model.description as string | undefined)" rows="5" cols="30"
          style="width: 100%;" />
        <label for="description">Description</label>
      </span>
    </template>
    <template #footer>
      <!-- || !propPool.isActive -->
      <Button icon="pi pi-check"
        :disabled="propPool == null || (model.amount == undefined || model.amount <= 0 || model.amount == null)"
        v-on:click="reserve" label="Reserve Serial Numbers" />

    </template>
  </ConCard>
</template>

<script setup lang="ts">

import { type SerialNumberReservationsCreateRequest, SerialNumberReservationsService, type SerialNumberReservationsCreateResponse } from "@/shared/services/athene-api";
import Textarea from 'primevue/textarea';
import InputNumber from 'primevue/inputnumber';
import { type Ref, defineComponent, ref } from "vue";
import { useToast } from "primevue/usetoast";
import Button from "primevue/button";
import { ConCard } from '@congatec/primevue-components-lib';

const props = defineProps({
  propPool: {
    type: Object,
    default: null
  },
  propShowHeader: {
    type: Boolean,
    default: false,
  },
  propShowSubHeader: {
    type: Boolean,
    default: true,
  },
});

const model: Ref<SerialNumberReservationsCreateRequest> = ref({
  poolId: undefined,
  amount: undefined,
  description: undefined,
});
const isLoading = ref(false);

const serialNumberReservations: Ref<SerialNumberReservationsCreateResponse | null> = ref(null);

const toast = useToast();

async function reserve() {
  if (
    props.propPool != null &&
    model.value != null &&
    model.value.amount != null &&
    model.value.amount > 0
  ) {
    isLoading.value = true;
    try {

      let response = await SerialNumberReservationsService.postApiV1SerialNumberReservations({
        amount: model.value.amount,
        poolClusterId: props.propPool.id,
        description: model.value.description

      } as SerialNumberReservationsCreateRequest)

      model.value = { amount: undefined, description: undefined, poolClusterId: props.propPool.id };
      if (response) {
        isLoading.value = false;
        serialNumberReservations.value = response;
        emit(
          SerialNumberReservationCreateViewEvents.SerialNumberReservationCreateSuccess,
          response.reservationIds
        );
      }
      isLoading.value = false;
    }
    catch (error: any) {
      isLoading.value = false;
      console.log("error log: ", error.stack);
      toast.add({ severity: 'error', summary: 'An error has occured: ', detail: error.body.Error, life: 3000 });
    };
  }
}

const emit =
  defineEmits<{
    (event: "SerialNumberReservationCreateSuccess", serialNumberReservations: any): void;
  }>();
</script>
<script lang="ts">
export default defineComponent({
  name: "SerialNumberReservationCreateView",
});

export enum SerialNumberReservationCreateViewEvents {
  SerialNumberReservationCreateSuccess = "SerialNumberReservationCreateSuccess",
}
</script>
